<script setup lang="ts">
import { AgTableServer } from '@/modules/common/components/ag-table';
import i18n from '@/localisation/i18n';
import { logger } from '@/modules/common/services/logger.service';
import { useSnackbarManager } from '@/modules/snackbar/services/snackbar-manager';
import { Agreement, AgreementStatus, PlainMessage, serviceAgreements } from '@/connect';
import { LoadSuccessParams } from 'ag-grid-enterprise';
import * as cols from '@/modules/common/components/ag-table/columns/agreements';
import { computed } from 'vue';
import { EventReadyConfig } from '@/modules/common/components/ag-table/AgTableServer.vue';
import { useHasPermission, useIsIncoming } from '@/modules/agreements/composables';

function getAgreementActions(availableActions: {
  view: (agreement: Agreement) => void;
  duplicate: (agreement: Agreement) => void;
  edit: (agreement: Agreement) => void;
  close: (agreement: Agreement) => void;
  review: (agreement: Agreement) => void;
}) {
  return function (agreement: Agreement) {
    const actions: {
      view?: () => void;
      duplicate?: () => void;
      edit?: () => void;
      close?: () => void;
      review?: () => void;
    } = {};
    const canEditAgreement = useHasPermission('agreement-edit');
    switch (agreement.status) {
      case AgreementStatus.DRAFT: {
        const isIncoming = useIsIncoming(agreement);
        if (isIncoming.value) {
          actions.review = () => availableActions.view(agreement);
          return actions;
        }
        if (canEditAgreement.value) {
          actions.edit = () => availableActions.edit(agreement);
          actions.close = () => availableActions.close(agreement);
        }
        break;
      }
      case AgreementStatus.ACTIVE:
        if (canEditAgreement.value) {
          actions.edit = () => availableActions.edit(agreement);
          actions.close = () => availableActions.close(agreement);
          actions.duplicate = () => availableActions.duplicate(agreement);
        }
        break;
      case AgreementStatus.REJECTED:
      case AgreementStatus.CANCELED:
      case AgreementStatus.INACTIVE:
        if (canEditAgreement.value) {
          actions.duplicate = () => availableActions.duplicate(agreement);
        }
        break;
    }
    actions.view = () => availableActions.view(agreement);
    return actions;
  };
}

/* eslint-disable @typescript-eslint/unified-signatures */
const emit = defineEmits<{
  (event: 'view', agreement: Agreement): void;
  (event: 'duplicate', agreement: Agreement): void;
  (event: 'edit', agreement: Agreement): void;
  (event: 'close', agreement: Agreement): void;
  (event: 'ready', data: EventReadyConfig): void;
  (event: 'review', agreement: Agreement): void;
  (event: 'update:reserved-short-names', reservedShortNames: Set<string>): void;
}>();
/* eslint-enable @typescript-eslint/unified-signatures */

const canCreateOrEditAgreement = useHasPermission('agreement-edit', 'agreement-create');

const columnDefs = computed(() => [
  cols.id(),
  cols.status(),
  cols.borrowerCompanyId(),
  cols.lenderCompanyId(),
  cols.shortName(),
  cols.settlementType(),
  cols.independentAmountRate(),
  cols.roundingRule(),
  cols.createdBy(),
  cols.approvedBy(),
  cols.createdDateTime(),
  cols.approvedDateTime(),
  cols.actions(
    canCreateOrEditAgreement.value ? 4 : 1,
    getAgreementActions({
      view: (agreement) => emit('view', agreement),
      duplicate: (agreement) => emit('duplicate', agreement),
      edit: (agreement) => emit('edit', agreement),
      close: (agreement) => emit('close', agreement),
      review: (agreement) => emit('review', agreement),
    })
  ),
]);

function getRowId(agreement: Agreement): string {
  return agreement.id;
}

const snackbarManager = useSnackbarManager();

async function queryData(): Promise<LoadSuccessParams | undefined> {
  try {
    const result = await serviceAgreements.queryAgreements({
      statuses: canCreateOrEditAgreement.value ? [] : [AgreementStatus.ACTIVE],
    });
    if (!result.success) {
      snackbarManager.error(i18n.t(result.error, result.details) as string);
      return;
    }

    for (const agreement of result.data.agreements) {
      if (agreement.shortName === agreement.displayId) {
        agreement.shortName = '';
      }
    }
    populateReservedShortNames(result.data.agreements);

    return { rowData: result.data.agreements, rowCount: result.data.agreements.length };
  } catch (e) {
    logger.warn(e);
  }
}

function populateReservedShortNames(agreements: Array<PlainMessage<Agreement>>) {
  emit(
    'update:reserved-short-names',
    new Set(agreements.map((agreement) => agreement.shortName.trim().toUpperCase()).filter(Boolean))
  );
}
</script>

<template>
  <AgTableServer
    :column-defs="columnDefs"
    data-test-id="AgreementsTable"
    :get-row-id="getRowId"
    no-menu
    no-move
    no-sort
    :page="1"
    :page-size="Infinity"
    :query-data="queryData"
    sort="id"
    table-id="AgreementsTable"
    v-on="$listeners"
    @ready="(e) => emit('ready', e)"
  />
</template>
