/* eslint-disable vue/one-component-per-file */

import { Decimal } from 'decimal.js';
import { ColDef, ValueFormatterParams } from 'ag-grid-enterprise';
import { VChip } from 'vuetify/lib';
import {
  alignCenter,
  alignLeft,
  alignRight,
  comparator,
  component,
  enumValueFormatter,
} from './utils';
import {
  getStatus,
  roundingRuleAbbr,
  timeInForceAbbr,
} from '@/modules/marketplace/helpers/marketplace';
import MarketplaceOrdersTableActions from '@/modules/marketplace/components/MarketplaceOrdersTableActions.vue';
import * as cols from '@/modules/common/components/ag-table/columns/common';
import { h } from 'vue';
import RateOutput from '@/modules/common/components/format-rate/RateOutput.vue';
import {
  OmsOrderStatus,
  OmsOrderType,
  OmsTimeInForceType,
  RoutingStatus,
} from '@/connect/gen/consts/omsconsts_pb';
import { Side } from '@/connect/gen/consts/commonconsts_pb';
import { ExecutionRecord, Order } from '@/connect/gen/modules/apiengine/services/oms/oms_pb';
import { useStoreSecurities } from '@/store/store-securities';
import { useStoreCompanies } from '@/store/store-companies';
import { OrderStatus, OrderType } from '@/modules/marketplace/types/marketplace';
import { OmsOrder } from '@/modules/marketplace/models';
import { useStoreAgreements } from '@/store/store-agreements';
import { formatPrettyNumber } from '@/modules/common/components/pretty-number';
import { PbRateDetails, formatSettlementType } from '@/connect';
import { benchmarkFromProtoEnum } from '@/utils/api/loans';
import { formatPbRateDetails } from '@/modules/common/components/ag-table/columns/common';

export { checkbox, settlementTypeString, settlementTypeEnum } from './common';

const statusAdapter = component<{
  order: Order;
  viewOrder: (orderRef: string) => void;
  label: string;
  color: string;
}>(
  (props) => () =>
    h(
      VChip,
      {
        style: 'width: 108px',
        class: `justify-center ${
          props.order.orderType === OmsOrderType.IOI &&
          props.order.orderStatus === OmsOrderStatus.OPEN
            ? 'text-uppercase'
            : 'text-lowercase'
        }`,
        props: { color: props.color, small: true },
        on: { click: () => props.viewOrder(props.order.orderRef) },
      },
      props.label
    )
);
export function status({ viewOrder }: { viewOrder: (orderRef: string) => void }): ColDef<Order> {
  return {
    field: 'orderStatus',
    colId: 'orderStatus',
    headerName: 'Status',
    ...enumValueFormatter(OmsOrderStatus),
    cellRendererSelector: (params) => {
      return statusAdapter({
        order: params.data!,
        viewOrder,
        ...getStatus({
          status: OmsOrderStatus[params.value] as OrderStatus,
          orderType: (params.data ? OmsOrderType[params.data?.orderType] : 'LIMIT') as OrderType,
          filled: Number(params.data?.totalExecQty),
        } as unknown as OmsOrder),
      });
    },
    resizable: false,
    pinned: 'left',
    minWidth: 108,
    width: 108,
    ...alignCenter(),
  };
}

export function side(): ColDef<Order> {
  return {
    field: 'side',
    colId: 'side',
    headerName: 'Side',
    ...enumValueFormatter(Side),
    cellRendererSelector: (params) =>
      cols.formatSideAdapter({
        side: params.value === Side.BORROWER ? 'BORROWER' : 'LENDER',
      }),
    ...alignLeft(),
  };
}

export function cusip(): ColDef<Order> {
  return {
    field: 'cusip',
    colId: 'cusip',
    headerName: 'CUSIP',
    ...alignLeft(),
  };
}

export function ticker(): ColDef<Order> {
  return {
    field: 'cusip',
    colId: 'security.ticker',
    headerName: 'Ticker',
    valueFormatter: (params) => useStoreSecurities().getSecurity(params.value)?.ticker ?? '–',
    ...alignLeft(),
  };
}

export function active(): ColDef<Order> {
  return {
    field: 'routingStatus',
    colId: 'routingStatus',
    headerName: 'Active',
    valueFormatter: (params) => {
      return params.value === RoutingStatus.ROUTED
        ? 'Active'
        : params.value === RoutingStatus.PENDING
          ? 'Pending'
          : '–';
    },
    ...alignLeft(),
  };
}

export function company(): ColDef<Order> {
  return {
    field: 'companyId',
    colId: 'companyId',
    headerName: 'Company',
    valueFormatter: (params) => {
      const cp = useStoreCompanies().getCompany(params.value);
      return `${cp?.companyName} ${cp?.displayBoxId != null ? ' (' + cp.displayBoxId + ')' : ''}`;
    },
    ...alignLeft(),
  };
}

export function rate(): ColDef<Order> {
  return {
    field: 'rate',
    colId: 'rate',
    headerName: 'Rate Limit',
    cellRendererSelector: (params) =>
      cols.rateAdapter({
        rate: params.value,
        rateModifier: undefined,
      }),
    comparator: comparator.decimal,
    cellDataType: 'text',
    ...alignRight({ hasPostfix: true }),
  };
}

export function rateFromRateDetails(): ColDef<ExecutionRecord> {
  return {
    field: 'rateDetails',
    colId: 'rate',
    headerName: 'Rate Limit',
    cellRendererSelector: (params) =>
      cols.rateAdapter({
        rate: (params.value as PbRateDetails).rate,
        rateModifier: benchmarkFromProtoEnum((params.value as PbRateDetails).benchmark),
      }),
    comparator: (a: PbRateDetails, b: PbRateDetails) => comparator.decimal(a.rate, b.rate),
    valueFormatter: (params: ValueFormatterParams<ExecutionRecord, PbRateDetails>) =>
      params.value ? formatPbRateDetails(params.value) : '-',
    cellDataType: 'text',
    ...alignRight({ hasPostfix: true }),
  };
}

export const openQuantity = (): ColDef<Order> =>
  cols.quantity({ field: 'openQuantity', headerName: 'Open Qty' });

export const execQty = (): ColDef<Order> => {
  return {
    ...cols.quantity({ field: 'totalExecQty', colId: 'totalExecQty', headerName: 'Exec Qty' }),
    valueFormatter: (params: ValueFormatterParams<Order, bigint>) =>
      !params.value ? '–' : formatPrettyNumber(params.value),
  };
};

const avgExecutionRateAdapter = component<{
  rate: number | string | Decimal | null;
}>((props) => () => (props.rate === null ? h('span', '–') : h(RateOutput, { props })));

export function avgExecRate(): ColDef<Order> {
  return {
    field: 'avgExecRate',
    colId: 'avgExecRate',
    headerName: 'Avg Exec Rate',
    cellRendererSelector: (params) =>
      avgExecutionRateAdapter({
        rate: params.value,
      }),
    comparator: (a, b) => comparator.decimal(a?.avgExecutionRate, b?.avgExecutionRate),
    cellDataType: 'text',
    ...alignRight(),
  };
}

export const totalQuantity = (): ColDef<Order> =>
  cols.quantity({ field: 'quantity', headerName: 'Total Qty' });

export const createTime = (): ColDef<Order> =>
  cols.timestamp({ field: 'createdAt', headerName: 'Create Time' });

export const updateTime = (): ColDef<Order> =>
  cols.timestamp({ field: 'updatedAt', headerName: 'Update Time' });

export const execTime = (): ColDef<ExecutionRecord> =>
  cols.timestamp({ field: 'execTime', headerName: 'Matched At' });

export const tradeDate = (): ColDef<ExecutionRecord> =>
  cols.date({ field: 'tradeDate', headerName: 'Trade Date' });

export function counterparty(): ColDef<ExecutionRecord> {
  return {
    field: 'counterpartyId',
    colId: 'counterpartyId',
    headerName: 'Counterparty',
    valueFormatter: (params) => {
      const cp = useStoreCompanies().getCompany(params.value);
      return `${cp?.companyName} ${cp?.displayBoxId != null ? ' (' + cp.displayBoxId + ')' : ''}`;
    },
    ...alignLeft(),
  };
}

export const executedQuantity = (): ColDef<ExecutionRecord> => {
  return {
    ...cols.quantity({ field: 'execQty', colId: 'quantity', headerName: 'Exec Qty' }),
    valueFormatter: (params: ValueFormatterParams<ExecutionRecord, bigint>) =>
      !params.value ? '–' : formatPrettyNumber(params.value),
  };
};

export function independentAmountRate(): ColDef<ExecutionRecord> {
  return {
    field: 'independentAmountRate',
    colId: 'independentAmountRate',
    headerName: 'IA',
    cellRendererSelector: (params) =>
      cols.rateAdapter({
        rate: params.value,
        rateModifier: undefined,
        precision: undefined,
      }),
    comparator: comparator.decimal,
    cellDataType: 'text',
    ...alignRight({ hasPostfix: true }),
  };
}

export function roundingRule(): ColDef<ExecutionRecord> {
  return {
    field: 'roundingRule',
    colId: 'roundingRule',
    headerName: 'Rounding',
    minWidth: 120,
    width: 120,
    valueFormatter: (params) => {
      return roundingRuleAbbr(params.value);
    },
    ...alignLeft(),
  };
}

export const rateType = (): ColDef<ExecutionRecord> => {
  return {
    colId: 'rateType',
    headerName: 'Rate Type',
    valueGetter: () => 'Rebate',
    ...alignLeft(),
  };
};

export const priceCurrency = (): ColDef<ExecutionRecord> => {
  return {
    colId: 'priceCurrency',
    headerName: 'Currency',
    valueGetter: () => 'USD',
    ...alignLeft(),
  };
};

export function unitPrice(): ColDef<ExecutionRecord> {
  return cols.price({ field: 'price', colId: 'price', headerName: 'Unit Price' });
}

export const settlementAmount = (): ColDef<ExecutionRecord> => {
  return cols.price({ field: 'settlementAmount', headerName: 'Settlement Amount' });
};

export function settlementType(): ColDef<ExecutionRecord> {
  return {
    field: 'settlementType',
    colId: 'settlementType',
    headerName: 'Sett Type',
    valueFormatter: (params) => formatSettlementType(params.value!),
    ...alignLeft(),
  };
}

export const collateralType = (): ColDef<ExecutionRecord> => {
  return {
    colId: 'collateralType',
    headerName: 'Collateral Type',
    valueGetter: () => 'Cash',
    ...alignLeft(),
  };
};

const orderTypeAdapter = component<{ orderType: string }>(
  (props) => () =>
    h(
      'span',
      { class: 'text-capitalize' },
      props.orderType !== 'IOI' ? props.orderType.toLowerCase() : props.orderType
    )
);

export function orderType(): ColDef<Order> {
  return {
    field: 'orderType',
    colId: 'orderType',
    headerName: 'Type',
    ...enumValueFormatter(OmsOrderType),
    cellRendererSelector: (params) => {
      return orderTypeAdapter({
        orderType: OmsOrderType[params.value],
      });
    },
    ...alignLeft(),
  };
}

export function timeInForce(): ColDef<Order> {
  return {
    field: 'timeInForceType',
    colId: 'timeInForceType',
    headerName: 'Time in Force',
    valueFormatter: (params) => {
      return timeInForceAbbr(OmsTimeInForceType[params.value]);
    },
    ...alignLeft(),
  };
}

export function minQuantity(): ColDef<Order> {
  return {
    field: 'minQuantity',
    colId: 'minQuantity',
    headerName: 'Min. Quantity',
    valueFormatter: (params: ValueFormatterParams<Order, bigint>) =>
      params.value === 1n || !params.value ? '–' : formatPrettyNumber(params.value),
    ...alignRight(),
  };
}

export function agreements(): ColDef<Order> {
  return {
    field: 'agreementIds',
    colId: 'agreementIds',
    headerName: 'Agreements',
    valueFormatter: (params) =>
      params.value.length
        ? params.value.map((id) => useStoreAgreements().getAgreement(id)?.displayId).join(', ')
        : 'Any',
    ...alignLeft(),
  };
}

export function orderRef(): ColDef<Order> {
  return {
    field: 'orderRef',
    colId: 'orderRef',
    headerName: 'Order Ref',
    ...alignLeft(),
  };
}

export function orderDisplayId(): ColDef<ExecutionRecord> {
  return {
    field: 'orderDisplayId',
    colId: 'orderDisplayId',
    headerName: 'Order Ref',
    ...alignLeft(),
  };
}

export function auroraLoanID(): ColDef<ExecutionRecord> {
  return {
    field: 'tradeId',
    colId: 'tradeId',
    headerName: 'Loan',
    ...alignLeft(),
  };
}

const actionsAdapter = component<{
  order: Order;
  viewOrder?: (orderRef: string) => void;
  editOrder?: (order: Order) => void;
  actions?: string[];
}>((props) => () => h(MarketplaceOrdersTableActions, { props }));

export function actions({
  viewOrder,
  editOrder,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  actions = ['view', 'edit', 'route', 'unroute', 'cancel'],
}: {
  viewOrder?: (orderRef: string) => void;
  editOrder?: (order: Order) => void;
  actions?: string[];
}): ColDef<Order> {
  return {
    colId: 'actions',
    headerName: 'Actions',
    cellRendererSelector: (params) =>
      actionsAdapter({
        order: params.data!,
        viewOrder,
        editOrder,
        actions,
      }),
    pinned: 'right',
    lockVisible: true,
    width: 150,
    maxWidth: 150,
    suppressColumnsToolPanel: true,
    ...alignCenter(),
  };
}
