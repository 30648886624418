import { QueryMarketplaceItem } from '@/connect/gen/modules/apiengine/services/venue/venue_pb';

export function getRowId(params: {
  data: QueryMarketplaceItem | { cusip: string } | null;
}): string {
  // AgGrid may still be rendering the row or fetching data
  if (!params.data) return '';
  if ('cusip' in params.data) {
    return params.data.cusip; // row group
  } else {
    const borrowRef = params.data.borrowOrder ? params.data.borrowOrder.clientOrderRef : '';
    const lendRef = params.data.lendOrder ? params.data.lendOrder.clientOrderRef : '';
    return `${borrowRef}_${lendRef}`; // order row
  }
}
