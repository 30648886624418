import { component } from '@/modules/common/components/ag-table/columns/utils';
import { h } from 'vue';
import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  ITooltipParams,
  NestedFieldPaths,
  ValueFormatterParams,
} from 'ag-grid-enterprise';
import { useStoreAgreements } from '@/store/store-agreements';
import { OmsOrderType } from '@/connect/gen/consts/omsconsts_pb';
import { formatPrettyNumber } from '@/modules/common/components/pretty-number';
import Decimal from 'decimal.js';
import {
  BookOrder,
  QueryMarketplaceItem,
} from '@/connect/gen/modules/apiengine/services/venue/venue_pb';
import { getPriceAsString } from '@/utils/helpers/auction-numbers';
import MarketplaceExplorerActions from '@/modules/marketplace/components/MarketplaceExplorerActions.vue';
import { Side } from '@/connect/consts';
import { get as getProp } from 'lodash';

type Direction = 'borrow' | 'lend';

function getDirectionFromSide(side: Side): Direction {
  // it is more convinient to convert the type because of the BE response
  return side === Side.BORROWER ? 'borrow' : 'lend';
}

export function createColDefs(
  side: Side,
  oppositeSide: Side
): Array<ColDef<QueryMarketplaceItem> | ColGroupDef<QueryMarketplaceItem>> {
  const direction = getDirectionFromSide(side);
  const oppositeDirection = getDirectionFromSide(oppositeSide);

  return [
    cusipRowGroup(),
    {
      headerName: direction,
      headerClass: `${direction}-header text-capitalize justify-center`,
      children: [
        actions(direction),
        orderType(direction),
        agreements(direction),
        quantity(direction),
        contractAmount(direction),
        rate(direction, true),
      ],
    },
    {
      headerName: oppositeDirection,
      headerClass: `${oppositeDirection}-header text-capitalize justify-center`,
      children: [
        rate(oppositeDirection),
        contractAmount(oppositeDirection),
        quantity(oppositeDirection),
        agreements(oppositeDirection),
        orderType(oppositeDirection),
        actions(oppositeDirection),
      ],
    },
  ];
}

export const defaultColDef: ColDef = {
  sortable: false,
  filter: false,
  suppressHeaderMenuButton: false,
};

export function cusipRowGroup(): ColDef {
  return {
    field: 'cusip',
    colId: 'cusip',
    rowGroup: true,
    hide: true,
    valueFormatter: (
      params: ValueFormatterParams<{
        ticker: string;
        cusip: string;
        name: string;
        lastClosePrice: string;
      }>
    ) => {
      const data = params.data;
      if (!data) return '';
      return `${data?.ticker} – ${data?.name} (${data?.cusip}) – $${new Decimal(data?.lastClosePrice).toFixed(2)}`;
    },
  };
}

const actionsAdapter = component<{
  order: BookOrder;
  direction: Direction;
}>((props) => () => h(MarketplaceExplorerActions, { props }));

function actions(direction: Direction): ColDef<QueryMarketplaceItem> {
  return {
    colId: `${direction}Actions`,
    cellRendererSelector: (params) => {
      if (!params.data) return undefined;
      const order = params.data[`${direction}Order`];
      // no need to render actions if order is not present (pair with only 1 order)
      if (!order) return undefined;
      return actionsAdapter({ order, direction });
    },
    width: 160,
  };
}

function getField(
  direction: Direction,
  field: 'orderType' | 'quantity' | 'rate' | 'agreementIds' | 'contractAmount'
): NestedFieldPaths<QueryMarketplaceItem, unknown, []> {
  return `${direction === 'borrow' ? 'borrowOrder' : 'lendOrder'}.${field}` as NestedFieldPaths<
    QueryMarketplaceItem,
    unknown,
    []
  >;
}

function getIoiOrderClassRule(direction: Direction) {
  return (params: CellClassParams<QueryMarketplaceItem>) =>
    getProp(params.data, `${direction}Order.orderType`) === OmsOrderType.IOI;
}

export function orderType(direction: Direction): ColDef<QueryMarketplaceItem> {
  const field = getField(direction, 'orderType');
  return {
    field,
    colId: `${direction}OrderType`,
    headerName: 'Type',
    valueFormatter: (params: ValueFormatterParams<QueryMarketplaceItem, OmsOrderType>) => {
      switch (params.value) {
        case OmsOrderType.IOI:
          return 'IOI';
        case OmsOrderType.LIMIT:
          return 'Limit';
        case OmsOrderType.MARKET:
          // @TODO: check if BE is filtering them out, and if socket events are not being triggered for them
          // (if socket events are triggered, then orderType must be sent in the payload so FE can ignore them)
          // handled "just in case", but not relevant for the MarketplaceExplorer view
          // as they are never expected to be displayed (even momentarily)
          return 'Market';
        default:
          return '';
      }
    },
    cellClassRules: {
      'ioi-order': getIoiOrderClassRule(direction),
    },
    cellClass: 'align-left-with-ellipsis',
    floatingFilter: true,
    filter: 'agSetColumnFilter',
    filterParams: {
      values: ['IOI', 'Limit'],
      suppressSelectAll: true,
    },
  };
}

export function agreements(direction: Direction): ColDef<QueryMarketplaceItem> {
  const storeAgreements = useStoreAgreements();
  const field = getField(direction, 'agreementIds');
  return {
    field,
    colId: `${direction}Agreements`,
    headerName: 'Agreements',
    valueFormatter: (params: ValueFormatterParams<QueryMarketplaceItem, string[]>) => {
      return params.value
        ? params.value.map((id) => storeAgreements.getAgreement(id)?.shortName).join(', ')
        : '';
    },
    cellClassRules: {
      'ioi-order': getIoiOrderClassRule(direction),
    },
    cellClass: 'align-left-with-ellipsis',
    tooltipValueGetter: (params: ITooltipParams<QueryMarketplaceItem>) => {
      return params.value
        ? params.value
            .map((id) => {
              const agreement = storeAgreements.getAgreement(id);
              return `${agreement?.shortName} (${agreement?.displayId})`;
            })
            .join('\n')
        : '';
    },
  };
}

export function quantity(direction: Direction): ColDef<QueryMarketplaceItem> {
  const field = getField(direction, 'quantity');
  return {
    field,
    colId: `${direction}Quantity`,
    headerName: 'Quantity',
    valueFormatter: (params: ValueFormatterParams<QueryMarketplaceItem>) =>
      params.value ? formatPrettyNumber(params.value) : '',
    cellClassRules: {
      'ioi-order': getIoiOrderClassRule(direction),
    },
    headerClass: 'header-right',
    cellClass: 'align-right-with-ellipsis',
    enableCellChangeFlash: true,
    floatingFilter: true,
    filter: 'agNumberColumnFilter',
    filterParams: {
      filterOptions: ['equals', 'lessThan', 'greaterThan'],
      debounceMs: 200,
      maxNumConditions: 1,
      allowedCharPattern: '\\d\\,',
      numberParser: (text: string | null) => {
        if (!text) return null;
        return Number(text.replace(/,/g, ''));
      },
    },
  };
}

export function contractAmount(direction: Direction): ColDef<QueryMarketplaceItem> {
  const field = getField(direction, 'contractAmount');
  return {
    field,
    colId: `${direction}Value`,
    headerName: 'Value',
    valueFormatter: (params: ValueFormatterParams<QueryMarketplaceItem>) => {
      return params.value ? `$${getPriceAsString(params.value, 0)}` : '';
    },
    headerClass: 'header-right',
    cellClass: 'align-right-with-ellipsis',
    cellClassRules: {
      'ioi-order': getIoiOrderClassRule(direction),
    },
  };
}

export function rate(direction: Direction, isHighlighted = false): ColDef<QueryMarketplaceItem> {
  const field = getField(direction, 'rate');
  return {
    field,
    colId: `${direction}Rate`,
    headerName: 'Rate (%)',
    valueFormatter: (params: ValueFormatterParams<QueryMarketplaceItem>) =>
      params.value ? new Decimal(params.value).toFixed(3) : '',
    headerClass: 'header-right',
    cellClass: 'align-right-with-ellipsis',
    cellClassRules: {
      'highlighted-border-right': () => isHighlighted,
      'ioi-order': getIoiOrderClassRule(direction),
    },
    floatingFilter: true,
    filter: 'agNumberColumnFilter',
    filterParams: {
      filterOptions: ['equals', 'lessThan', 'greaterThan'],
      debounceMs: 200,
      maxNumConditions: 1,
      allowedCharPattern: '\\d\\.\\-',
      numberParser: (text: string | null) => {
        if (!text) return null;
        // never convert the string to a number,
        // pass the string as-is to the filter
        return text;
      },
    },
  };
}
