// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file modules/apiengine/services/venue/venue.proto (package aurora.core.apiengine.venuev1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Side } from "../../../../consts/commonconsts_pb.js";
import { OmsOrderType } from "../../../../consts/omsconsts_pb.js";
import { PbAgreementInfo, PbCompanyInfo, PbInstrumentInfo } from "../../../../common/protos/types_pb.js";

/**
 * @generated from message aurora.core.apiengine.venuev1.QueryOrderBookRequest
 */
export class QueryOrderBookRequest extends Message<QueryOrderBookRequest> {
  /**
   * Cusip or Ticker of the queried instrument
   *
   * @generated from field: string instrument = 1;
   */
  instrument = "";

  /**
   * use side to limit the search to `best bids` or `best asks`
   *
   * @generated from field: optional aurora.core.consts.common.Side side = 2;
   */
  side?: Side;

  /**
   * optionally restrict query to MARKET or LIMIT orders
   *
   * @generated from field: optional aurora.core.consts.oms.OmsOrderType order_type = 3;
   */
  orderType?: OmsOrderType;

  /**
   * optionally restrict query to orders with a minimum requested quantity
   *
   * @generated from field: optional int64 minimum_quantity = 6;
   */
  minimumQuantity?: bigint;

  /**
   * optionally restrict query to orders with a maximum requested quantity
   *
   * @generated from field: optional int64 maximum_quantity = 7;
   */
  maximumQuantity?: bigint;

  /**
   * optionally restrict query to orders with minimum requested rate
   *
   * @generated from field: optional string minimum_rate = 8;
   */
  minimumRate?: string;

  /**
   * optionally restrict query to orders with maximum requested rate
   *
   * @generated from field: optional string maximum_rate = 9;
   */
  maximumRate?: string;

  /**
   * optionally restrict query to orders with a minimum requested quantity
   *
   * @generated from field: optional int64 minimum_min_execution_quantity = 10;
   */
  minimumMinExecutionQuantity?: bigint;

  /**
   * optionally restrict query to orders with a maximum requested quantity
   *
   * @generated from field: optional int64 maximum_min_execution_quantity = 11;
   */
  maximumMinExecutionQuantity?: bigint;

  /**
   * optionally restrict query to orders with specific agreements
   *
   * @generated from field: repeated string agreement_ids = 12;
   */
  agreementIds: string[] = [];

  constructor(data?: PartialMessage<QueryOrderBookRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.venuev1.QueryOrderBookRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "instrument", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "side", kind: "enum", T: proto3.getEnumType(Side), opt: true },
    { no: 3, name: "order_type", kind: "enum", T: proto3.getEnumType(OmsOrderType), opt: true },
    { no: 6, name: "minimum_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 7, name: "maximum_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 8, name: "minimum_rate", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "maximum_rate", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "minimum_min_execution_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 11, name: "maximum_min_execution_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 12, name: "agreement_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryOrderBookRequest {
    return new QueryOrderBookRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryOrderBookRequest {
    return new QueryOrderBookRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryOrderBookRequest {
    return new QueryOrderBookRequest().fromJsonString(jsonString, options);
  }

  static equals(a: QueryOrderBookRequest | PlainMessage<QueryOrderBookRequest> | undefined, b: QueryOrderBookRequest | PlainMessage<QueryOrderBookRequest> | undefined): boolean {
    return proto3.util.equals(QueryOrderBookRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.venuev1.QueryOrderBookResponse
 */
export class QueryOrderBookResponse extends Message<QueryOrderBookResponse> {
  /**
   * @generated from field: repeated aurora.core.apiengine.venuev1.BookOrder items = 1;
   */
  items: BookOrder[] = [];

  /**
   * @generated from field: int64 total_count = 2;
   */
  totalCount = protoInt64.zero;

  constructor(data?: PartialMessage<QueryOrderBookResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.venuev1.QueryOrderBookResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "items", kind: "message", T: BookOrder, repeated: true },
    { no: 2, name: "total_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryOrderBookResponse {
    return new QueryOrderBookResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryOrderBookResponse {
    return new QueryOrderBookResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryOrderBookResponse {
    return new QueryOrderBookResponse().fromJsonString(jsonString, options);
  }

  static equals(a: QueryOrderBookResponse | PlainMessage<QueryOrderBookResponse> | undefined, b: QueryOrderBookResponse | PlainMessage<QueryOrderBookResponse> | undefined): boolean {
    return proto3.util.equals(QueryOrderBookResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.venuev1.QueryMarketplaceRequest
 */
export class QueryMarketplaceRequest extends Message<QueryMarketplaceRequest> {
  /**
   * Filters for borrow orders
   *
   * @generated from field: optional aurora.core.apiengine.venuev1.QueryMarketplaceFilter borrow_filter = 1;
   */
  borrowFilter?: QueryMarketplaceFilter;

  /**
   * Filters for lend orders
   *
   * @generated from field: optional aurora.core.apiengine.venuev1.QueryMarketplaceFilter lend_filter = 2;
   */
  lendFilter?: QueryMarketplaceFilter;

  /**
   * change the view based on the user's intention to borrow or lend
   *
   * @generated from field: aurora.core.consts.common.Side trade_intention = 3;
   */
  tradeIntention = Side.BORROWER;

  /**
   * Page number, 1 being the starting one
   *
   * @generated from field: optional int32 page_number = 4;
   */
  pageNumber?: number;

  /**
   * Number of orders per page
   *
   * @generated from field: optional int32 page_limit = 5;
   */
  pageLimit?: number;

  constructor(data?: PartialMessage<QueryMarketplaceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.venuev1.QueryMarketplaceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "borrow_filter", kind: "message", T: QueryMarketplaceFilter, opt: true },
    { no: 2, name: "lend_filter", kind: "message", T: QueryMarketplaceFilter, opt: true },
    { no: 3, name: "trade_intention", kind: "enum", T: proto3.getEnumType(Side) },
    { no: 4, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "page_limit", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryMarketplaceRequest {
    return new QueryMarketplaceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryMarketplaceRequest {
    return new QueryMarketplaceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryMarketplaceRequest {
    return new QueryMarketplaceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: QueryMarketplaceRequest | PlainMessage<QueryMarketplaceRequest> | undefined, b: QueryMarketplaceRequest | PlainMessage<QueryMarketplaceRequest> | undefined): boolean {
    return proto3.util.equals(QueryMarketplaceRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.venuev1.QueryMarketplaceResponse
 */
export class QueryMarketplaceResponse extends Message<QueryMarketplaceResponse> {
  /**
   * List of borrow and lend order pairs
   *
   * @generated from field: repeated aurora.core.apiengine.venuev1.QueryMarketplaceItem items = 1;
   */
  items: QueryMarketplaceItem[] = [];

  /**
   * Instruments used for the orders
   *
   * @generated from field: map<string, aurora.core.common.protos.PbInstrumentInfo> instruments = 2;
   */
  instruments: { [key: string]: PbInstrumentInfo } = {};

  /**
   * Companies used for the orders
   *
   * @generated from field: map<string, aurora.core.common.protos.PbCompanyInfo> companies = 3;
   */
  companies: { [key: string]: PbCompanyInfo } = {};

  /**
   * Agreements used for orders
   *
   * @generated from field: map<string, aurora.core.common.protos.PbAgreementInfo> agreements = 4;
   */
  agreements: { [key: string]: PbAgreementInfo } = {};

  constructor(data?: PartialMessage<QueryMarketplaceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.venuev1.QueryMarketplaceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "items", kind: "message", T: QueryMarketplaceItem, repeated: true },
    { no: 2, name: "instruments", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: PbInstrumentInfo} },
    { no: 3, name: "companies", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: PbCompanyInfo} },
    { no: 4, name: "agreements", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: PbAgreementInfo} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryMarketplaceResponse {
    return new QueryMarketplaceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryMarketplaceResponse {
    return new QueryMarketplaceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryMarketplaceResponse {
    return new QueryMarketplaceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: QueryMarketplaceResponse | PlainMessage<QueryMarketplaceResponse> | undefined, b: QueryMarketplaceResponse | PlainMessage<QueryMarketplaceResponse> | undefined): boolean {
    return proto3.util.equals(QueryMarketplaceResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.venuev1.QueryMarketplaceItem
 */
export class QueryMarketplaceItem extends Message<QueryMarketplaceItem> {
  /**
   * Borrow side order of the pair
   *
   * @generated from field: optional aurora.core.apiengine.venuev1.BookOrder borrow_order = 1;
   */
  borrowOrder?: BookOrder;

  /**
   * Lend side order of the pair
   *
   * @generated from field: optional aurora.core.apiengine.venuev1.BookOrder lend_order = 2;
   */
  lendOrder?: BookOrder;

  constructor(data?: PartialMessage<QueryMarketplaceItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.venuev1.QueryMarketplaceItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "borrow_order", kind: "message", T: BookOrder, opt: true },
    { no: 2, name: "lend_order", kind: "message", T: BookOrder, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryMarketplaceItem {
    return new QueryMarketplaceItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryMarketplaceItem {
    return new QueryMarketplaceItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryMarketplaceItem {
    return new QueryMarketplaceItem().fromJsonString(jsonString, options);
  }

  static equals(a: QueryMarketplaceItem | PlainMessage<QueryMarketplaceItem> | undefined, b: QueryMarketplaceItem | PlainMessage<QueryMarketplaceItem> | undefined): boolean {
    return proto3.util.equals(QueryMarketplaceItem, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.venuev1.QueryMarketplaceFilter
 */
export class QueryMarketplaceFilter extends Message<QueryMarketplaceFilter> {
  /**
   * Cusip or Ticker of the queried instruments
   *
   * @generated from field: repeated string instruments = 1;
   */
  instruments: string[] = [];

  /**
   * optionally restrict query to LIMIT or IOI orders
   *
   * @generated from field: optional aurora.core.consts.oms.OmsOrderType order_type = 2;
   */
  orderType?: OmsOrderType;

  /**
   * optionally restrict query to orders with a minimum requested quantity
   *
   * @generated from field: optional int64 minimum_quantity = 5;
   */
  minimumQuantity?: bigint;

  /**
   * optionally restrict query to orders with a maximum requested quantity
   *
   * @generated from field: optional int64 maximum_quantity = 6;
   */
  maximumQuantity?: bigint;

  /**
   * optionally restrict query to orders with minimum requested rate
   *
   * @generated from field: optional string minimum_rate = 7;
   */
  minimumRate?: string;

  /**
   * optionally restrict query to orders with maximum requested rate
   *
   * @generated from field: optional string maximum_rate = 8;
   */
  maximumRate?: string;

  /**
   * optionally restrict query to orders with a minimum execution quantity
   *
   * @generated from field: optional int64 minimum_min_execution_quantity = 9;
   */
  minimumMinExecutionQuantity?: bigint;

  /**
   * optionally restrict query to orders with a maximum execution quantity
   *
   * @generated from field: optional int64 maximum_min_execution_quantity = 10;
   */
  maximumMinExecutionQuantity?: bigint;

  constructor(data?: PartialMessage<QueryMarketplaceFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.venuev1.QueryMarketplaceFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "instruments", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "order_type", kind: "enum", T: proto3.getEnumType(OmsOrderType), opt: true },
    { no: 5, name: "minimum_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 6, name: "maximum_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 7, name: "minimum_rate", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "maximum_rate", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "minimum_min_execution_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 10, name: "maximum_min_execution_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryMarketplaceFilter {
    return new QueryMarketplaceFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryMarketplaceFilter {
    return new QueryMarketplaceFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryMarketplaceFilter {
    return new QueryMarketplaceFilter().fromJsonString(jsonString, options);
  }

  static equals(a: QueryMarketplaceFilter | PlainMessage<QueryMarketplaceFilter> | undefined, b: QueryMarketplaceFilter | PlainMessage<QueryMarketplaceFilter> | undefined): boolean {
    return proto3.util.equals(QueryMarketplaceFilter, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.venuev1.QueryMarketplaceInstrumentsRequest
 */
export class QueryMarketplaceInstrumentsRequest extends Message<QueryMarketplaceInstrumentsRequest> {
  /**
   * Filters for borrow orders
   *
   * @generated from field: optional aurora.core.apiengine.venuev1.QueryMarketplaceFilter borrow_filter = 1;
   */
  borrowFilter?: QueryMarketplaceFilter;

  /**
   * Filters for lend orders
   *
   * @generated from field: optional aurora.core.apiengine.venuev1.QueryMarketplaceFilter lend_filter = 2;
   */
  lendFilter?: QueryMarketplaceFilter;

  /**
   * change the view based on the user's intention to borrow or lend
   *
   * @generated from field: aurora.core.consts.common.Side trade_intention = 3;
   */
  tradeIntention = Side.BORROWER;

  constructor(data?: PartialMessage<QueryMarketplaceInstrumentsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.venuev1.QueryMarketplaceInstrumentsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "borrow_filter", kind: "message", T: QueryMarketplaceFilter, opt: true },
    { no: 2, name: "lend_filter", kind: "message", T: QueryMarketplaceFilter, opt: true },
    { no: 3, name: "trade_intention", kind: "enum", T: proto3.getEnumType(Side) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryMarketplaceInstrumentsRequest {
    return new QueryMarketplaceInstrumentsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryMarketplaceInstrumentsRequest {
    return new QueryMarketplaceInstrumentsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryMarketplaceInstrumentsRequest {
    return new QueryMarketplaceInstrumentsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: QueryMarketplaceInstrumentsRequest | PlainMessage<QueryMarketplaceInstrumentsRequest> | undefined, b: QueryMarketplaceInstrumentsRequest | PlainMessage<QueryMarketplaceInstrumentsRequest> | undefined): boolean {
    return proto3.util.equals(QueryMarketplaceInstrumentsRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.venuev1.QueryMarketplaceInstrumentsResponse
 */
export class QueryMarketplaceInstrumentsResponse extends Message<QueryMarketplaceInstrumentsResponse> {
  /**
   * @generated from field: map<string, aurora.core.common.protos.PbInstrumentInfo> instruments = 1;
   */
  instruments: { [key: string]: PbInstrumentInfo } = {};

  constructor(data?: PartialMessage<QueryMarketplaceInstrumentsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.venuev1.QueryMarketplaceInstrumentsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "instruments", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: PbInstrumentInfo} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryMarketplaceInstrumentsResponse {
    return new QueryMarketplaceInstrumentsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryMarketplaceInstrumentsResponse {
    return new QueryMarketplaceInstrumentsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryMarketplaceInstrumentsResponse {
    return new QueryMarketplaceInstrumentsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: QueryMarketplaceInstrumentsResponse | PlainMessage<QueryMarketplaceInstrumentsResponse> | undefined, b: QueryMarketplaceInstrumentsResponse | PlainMessage<QueryMarketplaceInstrumentsResponse> | undefined): boolean {
    return proto3.util.equals(QueryMarketplaceInstrumentsResponse, a, b);
  }
}

/**
 * single order in the depth-of-book
 *
 * @generated from message aurora.core.apiengine.venuev1.BookOrder
 */
export class BookOrder extends Message<BookOrder> {
  /**
   * borrow or lend- order
   *
   * @generated from field: aurora.core.consts.common.Side side = 1;
   */
  side = Side.BORROWER;

  /**
   * Requested quantity of shares
   *
   * @generated from field: int64 quantity = 2;
   */
  quantity = protoInt64.zero;

  /**
   * Rate and benchmark type for the order
   *
   * @generated from field: string rate = 4;
   */
  rate = "";

  /**
   * Minimum quantity of shares per execution - optional, defaults to 100
   *
   * @generated from field: optional int64 min_execution_quantity = 7;
   */
  minExecutionQuantity?: bigint;

  /**
   * Order type of the order
   *
   * @generated from field: aurora.core.consts.oms.OmsOrderType order_type = 8;
   */
  orderType = OmsOrderType.UNSPECIFIED_ORDER_TYPE;

  /**
   * agreements that the order can be matched under
   *
   * @generated from field: repeated string agreement_ids = 11;
   */
  agreementIds: string[] = [];

  /**
   * Cusip of the instrument
   *
   * @generated from field: string cusip = 17;
   */
  cusip = "";

  /**
   * Company ID for the order
   *
   * @generated from field: string company_id = 12;
   */
  companyId = "";

  /**
   * Contract amount
   *
   * @generated from field: string contract_amount = 13;
   */
  contractAmount = "";

  /**
   * Root client order reference
   *
   * @generated from field: string root_client_order_ref = 14;
   */
  rootClientOrderRef = "";

  /**
   * Client order reference
   *
   * @generated from field: string client_order_ref = 15;
   */
  clientOrderRef = "";

  constructor(data?: PartialMessage<BookOrder>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.venuev1.BookOrder";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "side", kind: "enum", T: proto3.getEnumType(Side) },
    { no: 2, name: "quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "rate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "min_execution_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 8, name: "order_type", kind: "enum", T: proto3.getEnumType(OmsOrderType) },
    { no: 11, name: "agreement_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 17, name: "cusip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "contract_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "root_client_order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "client_order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BookOrder {
    return new BookOrder().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BookOrder {
    return new BookOrder().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BookOrder {
    return new BookOrder().fromJsonString(jsonString, options);
  }

  static equals(a: BookOrder | PlainMessage<BookOrder> | undefined, b: BookOrder | PlainMessage<BookOrder> | undefined): boolean {
    return proto3.util.equals(BookOrder, a, b);
  }
}

