sele
<template>
  <v-card class="flex d-flex flex-column">
    <!-- Table header (filters, tabs) -->
    <v-container class="py-0" fluid>
      <v-row no-gutters>
        <v-col class="gap-1 d-flex align-center justify-end" cols="12">
          <!-- Filters -->
          <counterparty-search
            v-model="selectedCompany"
            class="companies"
            clearable
            placeholder="All companies"
          />
          <simple-security-search
            v-model="selectedSecurity"
            class="simple-security-search"
            clearable
            label="Security"
          />
          <v-select v-model="selectedSide" class="side" clearable :items="sideItems" label="Side" />
          <v-select v-model="selectedOrderType" clearable :items="orderTypeItems" label="Type" />
          <v-select
            v-model="selectedRouting"
            clearable
            :items="routingItems"
            label="Active/Inactive"
          />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-switch
                  v-model="showAllMarketplaceOrders"
                  class="mt-0"
                  data-test="show-all-orders"
                  :false-value="false"
                  hide-details
                  label="show archived"
                />
              </span>
            </template>
            <span class="tip-background">
              Enabling show archived will include in the results Orders that were closed before
              today
            </span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row class="mt-n2 mb-4" no-gutters>
        <!-- Tabs -->
        <v-tabs
          ref="tabs"
          background-color="transparent"
          :value="Object.values(tabs).indexOf(selectedTab)"
        >
          <v-tab
            v-for="tabName in Object.keys(tabs)"
            :key="tabName"
            :value="tabName"
            @change="selectedTab = tabs[tabName]"
          >
            {{ tabName }}
          </v-tab>
        </v-tabs>
      </v-row>
    </v-container>

    <marketplace-orders-table
      :actions="['view']"
      data-test="marketplace-orders-table"
      :query-data="queryData"
      :show-select="false"
      :sort="sort"
      @ready="onReady"
      @view-order="detailOrderRef = $event"
    />

    <marketplace-order-details-dialog
      v-if="detailOrderRef"
      :as-broker="true"
      :order-ref="detailOrderRef"
      @close-modal="detailOrderRef = null"
    />
  </v-card>
</template>

<script lang="ts">
import { CompanyInfo, Security } from '@/modules/common/models';
import SimpleSecuritySearch from '@/modules/manual-loan/components/SimpleSecuritySearch.vue';
import MarketplaceOrderDetailsDialog from '@/modules/marketplace/components/MarketplaceOrderDetailsDialog.vue';
import MarketplaceOrdersTable from '@/modules/marketplace/components/MarketplaceOrdersTable.vue';
import { LoginState, SocketEvents } from '@/store/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import CounterpartySearch from '@/modules/user-accounts/components/CounterpartySearch.vue';
import { LoadSuccessParams, SortModelItem } from 'ag-grid-enterprise';
import { serviceMarketplace } from '@/connect/services/marketplace';
import { QueryOrdersFilter } from '@/connect/gen/modules/apiengine/services/oms/oms_pb';
import { Side } from '@/connect/gen/consts/commonconsts_pb';
import { OmsOrderType, RoutingStatus } from '@/connect/gen/consts/omsconsts_pb';
import { useStoreSecurities } from '@/store/store-securities';
import { useStoreCompanies } from '@/store/store-companies';
import { useStoreAgreements } from '@/store/store-agreements';
import { Tab, tabs } from '@/modules/marketplace/helpers/marketplace';

function stubFn() {
  return;
}

@Component({
  components: {
    SimpleSecuritySearch,
    MarketplaceOrdersTable,
    MarketplaceOrderDetailsDialog,
    CounterpartySearch,
  },
  computed: {
    ...mapState(['loginState', 'socketEvents']),
    ...mapGetters(['hasTraderUserRole']),
  },
})
export default class BrokerMarketplaceOrdersList extends Vue {
  protected storeSecurities = useStoreSecurities();
  protected storeCompanies = useStoreCompanies();
  protected storeAgreements = useStoreAgreements();

  // store state
  protected loginState!: LoginState;
  protected hasTraderUserRole!: boolean;
  protected socketEvents!: SocketEvents;

  // store methods
  protected updateMarketplaceShowAll!: (showAll: boolean) => void;
  protected refreshAdminOmsOrders!: () => Promise<void>;

  protected sort: SortModelItem = { colId: 'updatedAt', sort: 'desc' };
  protected showAllMarketplaceOrders = false;
  protected detailOrderRef: string | null = null;
  protected selectedSecurity: Security | null = null;
  protected selectedCompany: CompanyInfo | null = null;
  protected sideItems = [
    { text: 'Lender', value: Side.LENDER },
    { text: 'Borrower', value: Side.BORROWER },
  ];
  protected selectedSide: Side | null = null;
  protected orderTypeItems = [
    { text: 'Limit', value: OmsOrderType.LIMIT },
    { text: 'IOI', value: OmsOrderType.IOI },
  ];
  protected selectedOrderType: OmsOrderType | null = null;
  protected routingItems = [
    { text: 'Active', value: RoutingStatus.ROUTED },
    { text: 'Pending', value: RoutingStatus.PENDING },
    { text: 'Inactive', value: RoutingStatus.UNROUTED },
  ];
  protected selectedRouting: RoutingStatus | null = null;
  protected tabs = tabs;
  protected selectedTab: Tab = tabs.open;

  protected tableRefresh: (config: { purge: boolean }) => void = stubFn;
  protected resetSelection!: () => void;
  protected getTotalCount!: () => number;

  @Watch('socketEvents.marketplace.orders')
  protected onSocketEvents(): void {
    // table is expected to remain more or less the same,
    // no need to purge the cache, just refresh and update the rows
    this.tableRefresh({ purge: false });
  }

  @Watch('selectedSecurity')
  @Watch('selectedSide')
  @Watch('selectedRouting')
  @Watch('selectedCompany')
  @Watch('selectedOrderType')
  @Watch('showAllMarketplaceOrders')
  @Watch('selectedTab')
  protected onChangeFilters(): void {
    this.resetSelection();
    // purge the cache to avoid slow UI animations
    this.tableRefresh({ purge: true });
  }

  protected onReady(config: {
    refresh: (config: { purge: boolean }) => void;
    resetSelection: () => void;
    getTotalCount: () => number;
  }): void {
    this.tableRefresh = config.refresh;
    this.resetSelection = config.resetSelection;
    this.getTotalCount = config.getTotalCount;
  }

  protected async queryData(config: {
    page: number;
    pageSize: number;
    sort: SortModelItem;
    signal: AbortSignal;
  }): Promise<LoadSuccessParams | undefined> {
    const result = await serviceMarketplace.queryOrders({
      ...config,
      filter: this.getQueryFilter(),
    });

    if (!result.success) {
      this.$snackbar.show({ message: this.$i18n.t(result.error, result.details) as string });
      return;
    }

    this.storeSecurities.addSecurities(result.data.instruments);
    this.storeCompanies.addCompanies(result.data.companies);
    this.storeAgreements.addAgreements(result.data.agreements);

    return { rowData: result.data.orders, rowCount: Number(result.data.totalCount) };
  }

  protected getQueryFilter(): QueryOrdersFilter {
    return new QueryOrdersFilter({
      instruments: this.selectedSecurity ? [this.selectedSecurity.cusip] : [],
      companyId: this.selectedCompany?.companyId ?? undefined,
      side: this.selectedSide ?? undefined,
      routingStatus: this.selectedRouting ?? undefined,
      orderStatus: this.selectedTab?.orderStatus ?? undefined,
      orderType: this.selectedOrderType ?? undefined,
      hasExecuted: this.selectedTab?.hasExecuted ?? undefined,
      showAll: this.showAllMarketplaceOrders,
    });
  }

  protected counterpartyItemText(counterparty: CompanyInfo): string {
    return `${counterparty.companyName} (${counterparty.displayBoxId})`;
  }
}
</script>

<style lang="scss" scoped>
.gap-1 {
  gap: 1rem;
}

.companies,
.simple-security-search,
.side {
  max-width: 20rem;
}
</style>
